interface Option {
  label: { [key: string]: string };
  value?: number | string;
}

export const educationOptions: Option[] = [
  { label: { en: "None", cy: "" }, value: "none" },
  { label: { en: "Primary School", cy: "Ysgol gynradd " }, value: "primary" },
  { label: { en: "Secondary School", cy: "Ysgol uwchradd " }, value: "secondary" },
  { label: { en: "University", cy: "Prifyscol" }, value: "higher" },
];
export const maritalStatusOptions: Option[] = [
  { label: { en: "Single", cy: "Sengl" }, value: "single" },
  { label: { en: "Married", cy: "Wedi priodi" }, value: "married" },
  { label: { en: "Separated", cy: "Wedi Gwanhau" }, value: "separated" },
  { label: { en: "Divorced", cy: "Wedi Ysgaru" }, value: "divorced" },
  { label: { en: "Widowed", cy: "Gweddw" }, value: "Widowed" },
];

export const genderOptions: Option[] = [
  { label: { en: "Female", cy: "Benyw" }, value: "f" },
  { label: { en: "Male", cy: "Gwryw" }, value: "m" },
  { label: { en: "Rather not say", cy: "Byddai’n well gen i beidio â dweud" }, value: "n" },
];

import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Form, FormikFormProps } from "formik";
import { firestore } from "../fire";

import { peopleType } from "./library/people";

import QOLDemographics from "./components/QOLDemographics";
import QOLQuestion from "./components/QOLQuestion";
import questions from "./library/questions";
import styles from "./Form.module.scss";
import { withFormikDevtools } from "formik-devtools-extension";
import { emptyResponse } from "./library/response";
import ProgressBar from "../shared-components/ProgressBar";
import { useTranslation } from "react-i18next";

interface EndoFormProps {
  people: peopleType;
  setPeople: any;
}

export default function EndoForm({ people, setPeople }: EndoFormProps) {
  const history = useHistory();
  const initialValues = {
    a: emptyResponse,
    b: emptyResponse,
  };

  const [pageNumber, setPageNumber] = useState(-1);
  const { t } = useTranslation();
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          const filteredValues = (
            Object.keys(values) as Array<keyof typeof values>
          )
            .filter((key) => values[key])
            .reduce(
              (res, key) => (
                // @ts-expect-error: Heatley
                // eslint-disable-next-line
                (res[key] = values[key]), res
              ),
              {}
            );

          firestore
            .collection("qol")
            .add(filteredValues)
            .then((docRef) => history.push(`/${docRef.id}`));
        }}
      >
        {(formikProps: FormikFormProps) => {
          withFormikDevtools(formikProps);

          return (
            <Form className={styles.form}>
              <ProgressBar max={questions.length} value={pageNumber + 1} />

              {pageNumber === -1 && (
                <div className={styles.section}>
                  <legend className={styles.sectionHeader}>
                    {t("aboutYouLegend")}
                  </legend>
                  <div className={styles.sectionIntro}>
                    {t("aboutYouSectionIntro")}
                  </div>

                  <QOLDemographics
                    setPeople={setPeople}
                    people={people}
                    person={people.a}
                  />
                  <QOLDemographics
                    setPeople={setPeople}
                    people={people}
                    person={people.b}
                  />
                </div>
              )}

              {Object.keys(questions).map(
                (question, i = 1) =>
                  pageNumber === i && (
                    <QOLQuestion
                      personAName={people.a.name}
                      personBName={people.b.name}
                      key={i}
                      question={questions[i]}
                    />
                  )
              )}

              <div className={styles.pagination}>
                <button
                  className={styles.prev}
                  type="button"
                  onClick={() => setPageNumber(pageNumber - 1)}
                  disabled={pageNumber < 0}
                >
                  {t("backButton")}
                </button>

                {pageNumber + 1 === questions.length && (
                  <button className={styles.submit} type="submit">
                    {t("submitButton")}
                  </button>
                )}

                {pageNumber + 1 < questions.length && (
                  <button
                    className={styles.next}
                    type="button"
                    onClick={() => setPageNumber(pageNumber + 1)}
                  >
                    {t("nextButton")}
                  </button>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

import React, { useState } from "react";
import { Switch, Route } from "react-router-dom";
import Intro from "./Intro";
import Form from "./Form";
import Results from "./Results";
import { peopleType } from "./library/people";

const QOL = () => {
  const [people, setPeople] = useState<peopleType>({
    a: {
      personId: "a",
      name: "Person A",
    },
    b: {
      personId: "b",
      name: "Person B",
    },
  });

  return (
    <Switch>
      <Route path="/" exact>
        <Intro />
      </Route>
      <Route path="/form" exact>
        <Form people={people} setPeople={setPeople} />
      </Route>
      <Route path="/:id">
        <Results people={people} />
      </Route>
    </Switch>
  );
};

export default QOL;

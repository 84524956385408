import React from "react";
import { Field, FieldProps } from "formik";
import RadioYesNo from "../../shared-components/RadioYesNo";
import { genderOptions } from "../library/demographicOptions";

import styles from "./QOLDemographics.module.scss";

import { personType, peopleType } from "../library/people";
import { useTranslation } from "react-i18next";

interface QOLDemographicsProps {
  people: peopleType;
  person: personType;
  setPeople: any;
}

const QOLDemographics = ({
  person,
  people,
  setPeople,
}: QOLDemographicsProps) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  return (
    <div>
      <h2>{person.name || `Person ${person.personId.toUpperCase()}`}</h2>
      <div className={styles.questionWrapper}>
        <label className={styles.question} htmlFor="nameA">
          {t("nameQuestionLabel")}
        </label>
        <div className={styles.questionHelp}>{t("nameQuestionHelp")}</div>
        <input
          className={styles.inputField}
          id="nameA"
          type="text"
          onChange={(e) => {
            setPeople({
              ...people,
              [person.personId]: {
                ...person,
                name: e.target.value,
              },
            });
          }}
          defaultValue={person.name}
        />
      </div>

      <div className={styles.questionWrapper}>
        <div className={styles.question}>{t("genderQuestion")}</div>
        <Field name={`demographics.${person.personId}.gender`}>
          {({ field, form }: FieldProps) => (
            <ol className={styles.radioList}>
              {genderOptions.map((option, i) => (
                <li key={i} className={styles.radioItem}>
                  <label
                    className={styles.label}
                    htmlFor={`demographics.${person.personId}.gender-${option.value}`}
                  >
                    <input
                      {...field}
                      id={`demographics.${person.personId}.gender-${option.value}`}
                      type="radio"
                      value={option.value}
                      onChange={() =>
                        form.setFieldValue(field.name, option.value)
                      }
                      checked={field.value === option.value}
                    />
                    <span className="screen-reader-text">{`Person A`}</span>
                    {option.label[language]}
                  </label>
                </li>
              ))}
            </ol>
          )}
        </Field>
      </div>

      <div className={styles.questionWrapper}>
        <div className={styles.question}>{t("hasEndoQuestion")}</div>
        <Field
          name={`demographics.${person.personId}.hasEndo`}
          component={RadioYesNo}
        />
      </div>
    </div>
  );
};

export default QOLDemographics;

import React from "react";
import { Link } from "react-router-dom";
import styles from "../shared-components/Page.module.scss";
import { useTranslation } from "react-i18next";

const Intro = () => {
  const { i18n } = useTranslation();
  return (
    <div>
     
      {i18n.language === "en" && (
        <>
         <h1 className={styles.pageTitle}>{`THE ENDOMETRIOSIS CYMRU ME & YOU EMPATHY QUIZ`}</h1>
          <p>
            {`The Me & You quiz asks 26 questions that help you describe your quality of life from your
        own perspective.`}
          </p>
          <p>
            {`Taking the quiz with a friend, relative or colleague gives you an opportunity to talk about your quality of life and how your life circumstances affect you, your relationships and daily activities.`}
          </p>
          <h2>{`Think about your lives for the past two weeks.`}</h2>
          <p>
            {`Read each question, assess and discuss your feelings. Then, for each question, tap the number on the scale that gives the best answer for each of you.`}
          </p>

      <Link className={styles.bigButton} to="/form">
       {`Start the quiz`}
      </Link>
        </>
      )}
      {i18n.language === "cy" && 
      <>
       <h1 className={styles.pageTitle}>{`CWIS EMPATHI FI A TI ENDOMETRIOSIS CYMRU`}</h1>
      <p>
        {`Mae’r cwis Ti a Fi yn gofyn 26 cwestiwn sy’n eich helpu i ddisgrifio ansawdd eich bywyd o’ch safbwynt chi.`}
      </p>
      <p>
        {`Drwy wneud y cwis gyda ffrind, perthynas neu gydweithiwr, byddwch yn cael cyfle i siarad am ansawdd eich bywyd a sut mae eich amgylchiadau bywyd yn effeithio arnoch chi, eich perthnasau, a’ch gweithgareddau dyddiol.`}
      </p>
      <h2>{`Meddyliwch am eich bywydau dros y pythefnos diwethaf.`}</h2>
      <p>
        {`Darllenwch bob cwestiwn, ac yna aseswch a thrafodwch eich teimladau. Yna, ar gyfer pob cwestiwn, pwyswch y rhif ar y raddfa sy’n rhoi’r ateb gorau i chi.`}
      </p>

      <Link className={styles.bigButton} to="/form">
       {`Dechrau’r Cwis`}
      </Link>
    </>}

    </div>
  );
};

export default Intro;

// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

// Add the Firebase products that you want to use
import "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCnHrSnmDukYnctFz2r8nChU9R_1cMi34E",
  authDomain: "endo-me-and-you-tool.firebaseapp.com",
  projectId: "endo-me-and-you-tool",
  storageBucket: "endo-me-and-you-tool.appspot.com",
  messagingSenderId: "1087005305596",
  appId: "1:1087005305596:web:0ecf689601c115cc7fc579",
  measurementId: "G-HN3SM81C81",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export const firestore = app.firestore();

import React from "react";
import { Field, FieldProps } from "formik";

import { question } from "../library/questions";
import styles from "./QOLQuestion.module.scss";
import { useTranslation } from "react-i18next";

interface QOLQuestionProps {
  question: question;
  personAName: string;
  personBName: string;
}

const QOLQuestion = ({
  personAName,
  personBName,
  question,
}: QOLQuestionProps) => {
  const options = question.answers;

  const {

    i18n: { language },
  } = useTranslation();

  return (
    <fieldset className={styles.section}>
      <legend className={styles.sectionHeader}>
        {question.text[language]}
      </legend>

      <table className={styles.table}>
        <thead className={styles.head}>
          <tr>
            <th>{personAName}</th>
            <th></th>
            <th>{personBName}</th>
          </tr>
        </thead>
        <tbody>
          <tr className={styles.optionsRow}>
            <td>
              <Field className={styles.optionsA} name={`a.${question.number}`}>
                {({ field, form }: FieldProps) => (
                  <ol className={styles.optionRadios}>
                    {options.map((option) => (
                      <li
                        className={`${styles.option} ${
                          styles[String(option.value)]
                        }`}
                        key={option.value}
                      >
                        <label
                          className={
                            field.value.answer === option.value
                              ? styles.active
                              : styles.inactive
                          }
                          htmlFor={`a.${question.number}_${option.value}`}
                        >
                          <input
                            {...field}
                            id={`a.${question.number}_${option.value}`}
                            type="radio"
                            value={option.value}
                            onChange={() =>
                              form.setFieldValue(field.name, {
                                question: question.number,
                                answer: option.value,
                              })
                            }
                            checked={field.value.answer === option.value}
                          />
                          {option.value}{" "}
                          <span className="screen-reader-text">
                            {option.label[language]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ol>
                )}
              </Field>
            </td>
            <td className={styles.labelsCell}>
              <ol className={styles.optionLabels}>
                {options.map((option) => (
                  <li key={option.value}>{option.label[language]}</li>
                ))}
              </ol>
            </td>
            <td>
              <Field className={styles.optionsA} name={`b.${question.number}`}>
                {({ field, form }: FieldProps) => (
                  <ol className={styles.optionRadios}>
                    {options.map((option) => (
                      <li
                        className={`${styles.option} ${
                          styles[String(option.value)]
                        }`}
                        key={option.value}
                      >
                        <label
                          className={
                            field.value.answer === option.value
                              ? styles.active
                              : styles.inactive
                          }
                          htmlFor={`b.${question.number}_${option.value}`}
                        >
                          <input
                            {...field}
                            id={`b.${question.number}_${option.value}`}
                            type="radio"
                            value={option.value}
                            onChange={() =>
                              form.setFieldValue(field.name, {
                                question: question.number,
                                answer: option.value,
                              })
                            }
                            checked={field.value.answer === option.value}
                          />
                          {option.value}{" "}
                          <span className="screen-reader-text">
                            {option.label[language]}
                          </span>
                        </label>
                      </li>
                    ))}
                  </ol>
                )}
              </Field>
            </td>
          </tr>
        </tbody>
      </table>
    </fieldset>
  );
};

export default QOLQuestion;

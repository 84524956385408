import React from "react";
import { useTranslation } from "react-i18next";

import styles from "./LanguageSwitcher.module.scss";

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <button
        className={i18n.language === "cy" ? styles.active : styles.link}
        onClick={() => i18n.changeLanguage("cy")}
      >
        {t("cyButton")}
      </button>
      <button
        className={i18n.language === "en" ? styles.active : styles.link}
        onClick={() => i18n.changeLanguage("en")}
      >
        {t("enButton")}
      </button>
    </div>
  );
};
export default LanguageSwitcher;

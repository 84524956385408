// adapted from https://github.com/callumflack/phn-qol-survey

export interface answer {
  value: number;
  label: { [key: string]: string };
}

export interface answerSetsType {
  [name: string]: answer[];
}

const answerSets: answerSetsType = {
  satisfaction: [
    { value: 1, label: { en: "Very dissatisfied", cy: "Anfodlon iawn" } },
    { value: 2, label: { en: "Dissatisfied", cy: "Anfodlon" } },
    { value: 3, label: { en: "Neither satisfied nor dissatisfied", cy: "Ddim yn fodlon nac yn anfodlon" } },
    { value: 4, label: { en: "Satisfied", cy: "Bodlon" } },
    { value: 5, label: { en: "Very satisfied", cy: "Bodlon iawn" } },
  ],
  amountNoun: [
    { value: 1, label: { en: "Not at all", cy: "Ddim o gwbl" } },
    { value: 2, label: { en: "A little", cy: "Ychydig" } },
    { value: 3, label: { en: "A moderate amount", cy: "Rhywfaint" } },
    { value: 4, label: { en: "Very much", cy: "Llawer" } },
    { value: 5, label: { en: "An extreme amount", cy: "Yn eithafol" } },
  ],
  amountAdverb: [
    { value: 1, label: { en: "Not at all", cy: "Ddim o gwbl" } },
    { value: 2, label: { en: "A little", cy: "Ychydig" } },
    { value: 3, label: { en: "A moderate amount", cy: "Rhywfaint" } },
    { value: 4, label: { en: "Very much", cy: "Llawer" } },
    { value: 5, label: { en: "Extremely", cy: "Llawer iawn" } },
  ],
  amountCoverage: [
    { value: 1, label: { en: "Not at all", cy: "Ddim o gwbl" } },
    { value: 2, label: { en: "A little", cy: "Ychydig" } },
    { value: 3, label: { en: "Moderately", cy: "Rhywfaint" } },
    { value: 4, label: { en: "Mostly", cy: "Ar y mwyaf" } },
    { value: 5, label: { en: "Completely", cy: "Yn llwyr" } },
  ],

  quality: [
    { value: 1, label: { en: "Very poor", cy: "Gwael iawn" } },
    { value: 2, label: { en: "Poor", cy: "Gwael" } },
    { value: 3, label: { en: "Neither poor nor good", cy: "Y naill na'r llall" } },
    { value: 4, label: { en: "Good", cy: "Da" } },
    { value: 5, label: { en: "Very good", cy: "Da iawn" } },
  ],
  frequency: [
    { value: 1, label: { en: "Never", cy: "Erioied" } },
    { value: 2, label: { en: "Seldom", cy: "Anami" } },
    { value: 3, label: { en: "Quite often", cy: "Eithaf aml" } },
    { value: 4, label: { en: "Very often", cy: "Aml iawn" } },
    { value: 5, label: { en: "Always", cy: "Bob amser" } },
  ],
};

export default answerSets;

import React from "react";
import styles from "./ProgressBar.module.scss";
interface ProgressBarProps {
  max: number;
  value: number;
}

const ProgressBar = ({ max, value }: ProgressBarProps) => {
  const progress = ((value - 1) / max) * 100;
  const current = (value / max) * 100;

  return (
    <svg viewBox="0 0 100 6">
      <rect width="100" height="3" fill="#f2ece6" />
      <line
        className={styles.current}
        strokeWidth="3"
        x1="0"
        y1="1.5"
        x2={current}
        y2="1.5"
      />
      <line
        className={styles.progress}
        strokeWidth="3"
        x1="0"
        y1="1.5"
        x2={progress}
        y2="1.5"
      />
      <text
        textAnchor="end"
        x={100}
        y="5.25"
        fontSize="2"
      >{`${value}/${max}`}</text>
    </svg>
  );
};

export default ProgressBar;

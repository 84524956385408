// Most helpful breakdown of this is from here
// http://depts.washington.edu/seaqol/docs/Wq_bref.txt

import { Response } from "../library/response";
import domains from "../library/domains";

export interface valuesMap {
  [key: string]: number[];
}

export interface scoresMap {
  [key: string]: number;
}

const average = (a: number[]) => {
  let t = 0,
    i = 0;
  for (; i < a.length; i++) t += a[i];
  return t / a.length;
};

const calculateScores = (responses: Response): scoresMap => {
  const questionsPhysical = domains.physical.questions;
  const questionsPsychological = domains.psychological.questions;
  const questionsSocial = domains.social.questions;
  const questionsEnvironmental = domains.environment.questions;

  const questionsToReverse = [3, 4, 26];

  const scores: valuesMap = {
    physical: [],
    environment: [],
    social: [],
    psychological: [],
  };

  // const numQuestions = Object.keys(responses).length;

  Object.keys(responses).forEach((question) => {
    const questionNumber = Number(question);
    const questionAnswer = responses[questionNumber].answer;

    // skip empty responses
    if (typeof questionAnswer === "number") {
      // Reverse scores on negatively framed questions
      // 6-5 = 1
      // 6-4 = 2
      // 6-3 = 3
      // 6-2 = 4
      // 6-1 = 5
      const score = questionsToReverse.includes(questionNumber)
        ? 6 - questionAnswer
        : questionAnswer;

      switch (true) {
        case score && questionsPhysical.includes(questionNumber):
          scores.physical.push(score);
          break;
        case score && questionsPsychological.includes(questionNumber):
          scores.psychological.push(score);
          break;
        case score && questionsSocial.includes(questionNumber):
          scores.social.push(score);
          break;
        case score && questionsEnvironmental.includes(questionNumber):
          scores.environment.push(score);
          break;
        default:
          break;
      }
    }
  });

  const rawScores = {
    physical: scores.physical.reduce(
      (accumulator, value) => accumulator + value,
      0
    ),
    environment: scores.environment.reduce(
      (accumulator, value) => accumulator + value,
      0
    ),
    social: scores.social.reduce(
      (accumulator, value) => accumulator + value,
      0
    ),
    psychological: scores.psychological.reduce(
      (accumulator, value) => accumulator + value,
      0
    ),
  };

  const rawToPercentage = (scores: number[], rawScore: number) => {
    return Math.floor(((rawScore - scores.length) / (scores.length * 4)) * 100);
  };

  const percentageScores = {
    physical: rawToPercentage(scores.physical, rawScores.physical),
    psychological: rawToPercentage(
      scores.psychological,
      rawScores.psychological
    ),
    social: rawToPercentage(scores.social, rawScores.social),
    environment: rawToPercentage(scores.environment, rawScores.environment),
  };

  return {
    physical: percentageScores.physical,
    psychological: percentageScores.psychological,
    social: percentageScores.social,
    environment: percentageScores.environment,
    qol: Math.floor(
      average([
        percentageScores.physical,
        percentageScores.environment,
        percentageScores.social,
        percentageScores.psychological,
      ])
    ),
  };
};

export default calculateScores;

import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import ScrollToTop from "./utils/ScrollToTop";
import Header from "./Header";

import QOL from "./qol/QOL";
import { I18nextProvider, useTranslation } from "react-i18next";
import i18next from "i18next";
import queryString from "query-string";

import styles from "./App.module.scss";

const App = () => {
  const { t } = useTranslation();
  console.log(window.location);
  const parsed = queryString.parse(window.location.search);
  useEffect(() => {
    if (parsed.lang) {
      i18next.changeLanguage(parsed.lang as string);
    }
  }, []);
  return (
    <Router>
      <I18nextProvider i18n={i18next}>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{t("Endometriosis Cymru | Me & You Quiz")}</title>
          <meta
            name="description"
            content={t(
              "The Me & You quiz asks 26 questions that help you describe your quality of life from your own perspective.",
            )}
          />
          <link rel="canonical" href="https://meandyou.endometriosis.cymru" />
        </Helmet>
        <ScrollToTop />
        <div className={styles.App}>
          <div className={styles.HeaderWrapper}>
            <Header />
          </div>

          <div className={styles.ContentWrapper}>
            <div className={styles.Content}>
              <QOL />
            </div>
          </div>
        </div>
      </I18nextProvider>
    </Router>
  );
};

export default App;

interface domain {
  [name: string]: {
    label: { [key: string]: string };
    questions: number[];
    description: { [key: string]: string };
  };
}

const domains: domain = {
  physical: {
    label: { en: "Physical", cy: "Corfforol" },
    questions: [3, 4, 10, 15, 16, 17, 18],
    description: {
      en: `The physical domain questions cover: 
    <ul>
    <li>Activities of daily living</li>
    <li>Dependence on medicinal substances and medical aids</li>
    <li>Energy and fatigue</li>
    <li>Mobility, Pain and discomfort</li>
    <li>Sleep and rest</li>
    <li>Work Capacity</li>
    </ul>`,
      cy: `Mae’r cwestiynau corfforol yn cynnwys: 
      <ul>
      <li> Gweithgareddau bywyd bob dydd</li>
      <li> Dibyniaeth ar sylweddau meddygol a chymhorthion meddygol</li>
      <li> Egni a blinder</li>
      <li> Symudedd, poen ac anghysur</li>
      <li> Cwsg a gorffwys</li>
      <li> Y gallu i weithio</li>
      </ul>`,
    },
  },
  environment: {
    label: { en: "Environmental", cy: "Amgylcheddol" },
    questions: [8, 9, 12, 13, 14, 23, 24, 25],
    description: {
      en: `The environmental domain questions cover: 
    <ul>
    <li>Financial resources</li>
    <li>Freedom, physical safety and security</li>
    <li>Health and social care: accessibility and quality</li>
    <li>Home environment</li>
    <li>Opportunities for acquiring new information and skills</li>
    <li>Participation in and opportunities for recreation/leisure activities</li>
    <li>Physical environment (pollution/noise/traffic/climate)</li>
    <li>Transport</li>
    </ul>`,
      cy: `Mae’r cwestiynau amgylcheddol yn cynnwys:: 
      <ul>
      <li>Adnoddau ariannol</li>
      <li>Rhyddid, diogelwch, a diogelwch corfforol</li>
      <li>Iechyd a gofal cymdeithasol: hygyrchedd ac ansawdd</li>
      <li>Amgylchedd y cartref</li>
      <li>Cyfleoedd i gael gwybodaeth a sgiliau newydd</li>
      <li>Cyfranogiad a chyfleoedd ar gyfer gweithgareddau hamdden</li>
      <li>Amgylchedd corfforol (llygredd/sŵn/traffig/hinsawdd)</li>
      <li>Cludiant</li>
      </ul>`,
    },
  },
  social: {
    label: { en: "Social", cy: "Cymdeithasol" },
    questions: [20, 21, 22],
    description: {
      en: `The social domain questions cover: 
    <ul>
    <li>Personal relationships</li>
    <li>Social support</li>
    <li>Sexual activity</li>
    </ul>`,
      cy: `Mae’r cwestiynau cymdeithasol yn cynnwys:: 
      <ul>
      <li>Perthnasau personol</li>
      <li>Cymorth cymdeithasol</li>
      <li>Gweithgarwch rhywiol</li>
      </ul>`,
    },
  },
  psychological: {
    label: { en: "Psychological", cy: "Seicolegol" },
    questions: [5, 6, 7, 11, 19, 26],
    description: {
      en: `The psychological domain questions cover:
    <ul>
    <li>Bodily image and appearance</li>
    <li>Negative feelings</li>
    <li>Positive feelings</li>
    <li>Self-esteem</li>
    <li>Spirituality / Religion / Personal beliefs</li>
    <li>Thinking, learning, memory and concentration</li>
    </ul>`,
      cy: `Mae’r cwestiynau seicolegol yn cynnwys:
      <ul>
      <li>Delwedd ac ymddangosiad corfforol</li>
      <li>Teimladau negyddol</li>
      <li>Teimladau cadarnhaol</li>
      <li>Hunan-werth</li>
      <li>Ysbrydolrwydd / Crefydd / Credoau personol</li>
      <li>Meddwl, dysgu, cof a chanolbwyntio</li>
      </ul>`,
    },
  },
};

export default domains;

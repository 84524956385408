export interface Response {
  [key: number]: {
    question: number;
    answer?: number;
  };
}

export const emptyResponse: Response = {
  1: { question: 1 },
  2: { question: 2 },
  3: { question: 3 },
  4: { question: 4 },
  5: { question: 5 },
  6: { question: 6 },
  7: { question: 7 },
  8: { question: 8 },
  9: { question: 9 },
  10: { question: 10 },
  11: { question: 11 },
  12: { question: 12 },
  13: { question: 13 },
  14: { question: 14 },
  15: { question: 15 },
  16: { question: 16 },
  17: { question: 17 },
  18: { question: 18 },
  19: { question: 19 },
  20: { question: 20 },
  21: { question: 21 },
  22: { question: 22 },
  23: { question: 23 },
  24: { question: 24 },
  25: { question: 25 },
  26: { question: 26 },
};

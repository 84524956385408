// adapted from https://github.com/callumflack/phn-qol-survey
import answerSets, { answer } from "./answer-sets";

export interface question {
  number: number;
  text: { [key: string]: string };
  answers: answer[];
}

const questions: question[] = [
  {
    number: 1,
    text: {
      en: "How would you rate your quality of life?",
      cy: "Sut fyddech chi'n graddio ansawdd eich bywyd?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 2,
    text: {
      en: "How satisfied are you with your health?",
      cy: "Pa mor fodlon ydych chi ar eich iechyd?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 3,
    text: {
      en: "To what extent do you feel that physical pain prevents you from doing what you need to do?",
      cy: "I ba raddau ydych chi'n teimlo bod poen corfforol yn eich atal rhag gwneud yr hyn y mae angen i chi ei wneud?",
    },
    answers: answerSets.frequency,
  },
  {
    number: 4,
    text: {
      en: "How much do you need any medical treatment to function in your life?",
      cy: "Faint ydych chi'n dibynnu ar driniaeth feddygol yn eich bywyd o ddydd i ddydd?",
    },
    answers: answerSets.amountNoun,
  },
  {
    number: 5,
    text: {
      en: "How much do you enjoy life?",
      cy: "Faint ydych chi'n mwynhau bywyd?",
    },
    answers: answerSets.amountNoun,
  },
  {
    number: 6,
    text: {
      en: "To what extent do you feel your life to be meaningful?",
      cy: "I ba raddau ydych chi'n teimlo bod eich bywyd yn ystyrlon?",
    },
    answers: answerSets.amountNoun,
  },
  {
    number: 7,
    text: {
      en: "How well are you able to concentrate?",
      cy: "Pa mor dda allwch chi ganolbwyntio?",
    },
    answers: answerSets.amountAdverb,
  },
  {
    number: 8,
    text: {
      en: "How safe do you feel in your daily life?",
      cy: "Pa mor ddiogel ydych chi'n teimlo yn eich bywyd pob dydd?",
    },
    answers: answerSets.amountAdverb,
  },
  {
    number: 9,
    text: {
      en: "How healthy is your physical environment?",
      cy: "Pa mor iach yw eich amgylchedd ffisegol?",
    },
    answers: answerSets.amountAdverb,
  },
  {
    number: 10,
    text: {
      en: "Do you have enough energy for everyday life?",
      cy: "A oes gennych ddigon o egni ar gyfer bywyd pob dydd?",
    },
    answers: answerSets.amountCoverage,
  },
  {
    number: 11,
    text: {
      en: "Are you able to accept your bodily appearance?",
      cy: "A allwch dderbyn eich ymddangosiad corfforol?",
    },
    answers: answerSets.amountCoverage,
  },
  {
    number: 12,
    text: {
      en: "Do you have enough money to fit your needs?",
      cy: "A oes gennych ddigon o arian i ddiwallu eich anghenion?",
    },
    answers: answerSets.amountCoverage,
  },
  {
    number: 13,
    text: {
      en: "How available to you is information that you need in your day-to-day life?",
      cy: "Pa mor gaffaeladwy yw'r wybodaeth y mae ei hangen arnoch yn eich bywyd o ddydd i ddydd?",
    },
    answers: answerSets.amountCoverage,
  },
  {
    number: 14,
    text: {
      en: "To what extent do you have the opportunity for leisure activities?",
      cy: "I ba raddau mae gennych gyfle ar gyfer gweithgareddau hamdden?",
    },
    answers: answerSets.amountCoverage,
  },
  {
    number: 15,
    text: {
      en: "How well are you able to get around?",
      cy: "Pa mor dda yw eich symudedd?",
    },
    answers: answerSets.quality,
  },
  {
    number: 16,
    text: {
      en: "How satisfied are you with your sleep?",
      cy: "Pa mor fodlon ydych chi ar eich cwsg?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 17,
    text: {
      en: "How satisfied are you with your ability to perform your daily living activities?",
      cy: "Pa mor fodlon ydych chi ar eich gallu i gyflawni'ch gweithgareddau bywyd dyddiol?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 18,
    text: {
      en: "How satisfied are you with your capacity to work?",
      cy: "Pa mor fodlon ydych chi ar eich gallu i weithio?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 19,
    text: {
      en: "How satisfied are you with yourself?",
      cy: "Pa mor fodlon ydych chi â chi eich hun?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 20,
    text: {
      en: "How satisfied are you with your personal relationships?",
      cy: "Pa mor fodlon ydych chi ar eich perthnasoedd personol?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 21,
    text: {
      en: "How satisfied are you with your sex life?",
      cy: "Pa mor fodlon ydych chi ar eich bywyd rhyw?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 22,
    text: {
      en: "How satisfied are you with the support you get from your friends?",
      cy: "Pa mor fodlon ydych chi ar y gefnogaeth rydych yn ei gael gan eich ffrindiau?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 23,
    text: {
      en: "How satisfied are you with the conditions of your living space?",
      cy: "Pa mor fodlon ydych chi ar amodau eich cartref?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 24,
    text: {
      en: "How satisfied are you with your access to health services?",
      cy: "Pa mor fodlon ydych chi ar eich mynediad at wasanaethau iechyd?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 25,
    text: {
      en: "How satisfied are you with your transport?",
      cy: "Pa mor fodlon ydych chi â chi eich trafnidiaeth?",
    },
    answers: answerSets.satisfaction,
  },
  {
    number: 26,
    text: {
      en: "How often do you have negative feelings such as blue mood, despair, anxiety, depression?",
      cy: "Pa mor aml ydych chi'n caael teimladau negyddol megis teimlo'n isel, anobaith, gofid, iselder?",
    },
    answers: answerSets.frequency,
  },
];

export default questions;

import React from "react";
import { emptyResponse } from "../library/response";
import calculateScores from "../helpers/calculateScores";
import styles from "./QOLChart.module.scss";
import { useTranslation } from "react-i18next";
import { peopleType } from "../library/people";

interface QOLChartTypes {
  people: peopleType;
  a: typeof emptyResponse;
  b: typeof emptyResponse;
}

const QOLChart = ({ people, a, b }: QOLChartTypes) => {
  const scoresA = calculateScores(a);
  const scoresB = calculateScores(b);
  const domains = ["physical", "environment", "social", "psychological"];
  const yAxis = [0, 25, 50, 75, 100];

  const { t } = useTranslation();

  const chart = {
    width: 250,
    height: 100,
    margin: 25,
    strokeWidth: 0.5,
    barWidth: 22,
    spacing: 10,
  };

  const canvas = {
    originX: -chart.margin,
    originY: -chart.margin,
    width: chart.width + chart.margin * 2,
    height: chart.height + chart.margin * 1.7,
  };

  const fontSize = chart.margin * 0.2;
  const barTrack = chart.width / 4 - chart.spacing;

  const adjustScoreToY = (score: number) => {
    return score > 0 ? chart.height - score : chart.height;
  };

  return (
    <svg
      className={styles.chart}
      viewBox={`${canvas.originX} ${canvas.originY} ${canvas.width} ${canvas.height}`}
    >
      <rect
        x={canvas.originX}
        y={canvas.originY}
        width={canvas.width}
        height={chart.height + chart.margin * 2}
        fill="#f2ece6"
      />

      {domains.map((domain, i) => {
        const aX = barTrack * 0.25;
        const bX = barTrack * 0.75;

        return (
          <g
            key={domain}
            fontSize={fontSize}
            textAnchor="middle"
            transform={`translate(${
              chart.spacing / 2 + chart.spacing * i + barTrack * i
            }, 0)`}
          >
            <g>
              <text x={aX} y={chart.height + chart.margin / 3}>
                {people.a.name.toLocaleUpperCase() || t("personAGraphText")}
              </text>
              <text x={barTrack / 2} y={-chart.margin / 2}>
                {domain.toUpperCase()}
              </text>
              <text x={bX} y={chart.height + chart.margin / 3}>
                {people.b.name.toLocaleUpperCase() || t("personBGraphText")}
              </text>
            </g>
            <g strokeWidth={chart.barWidth}>
              <g>
                <line
                  className={styles.personA}
                  x1={aX}
                  x2={aX}
                  y1={chart.height}
                  y2={adjustScoreToY(scoresA[domain])}
                />
                <line
                  className={styles.personB}
                  x1={bX}
                  x2={bX}
                  y1={chart.height}
                  y2={adjustScoreToY(scoresB[domain])}
                />

                <g transform={`translate(0 ${-chart.margin / 4})`}>
                  {scoresA[domain] && (
                    <text x={aX} y={adjustScoreToY(scoresA[domain])}>
                      {`${scoresA[domain]}`}
                    </text>
                  )}
                  {scoresB[domain] && (
                    <text x={bX} y={adjustScoreToY(scoresB[domain])}>
                      {`${scoresB[domain]}`}
                    </text>
                  )}
                </g>
              </g>
            </g>
          </g>
        );
      })}

      <path
        fill="none"
        stroke="black"
        strokeWidth={chart.strokeWidth}
        d={`M0 0, 0 ${chart.height}, ${chart.width} ${chart.height}`}
      />
      <g className={styles.yAxis} fontSize={fontSize} textAnchor="end">
        {yAxis.map((val, i) => (
          <text
            key={i}
            x={-chart.margin / 4}
            y={adjustScoreToY(val) + fontSize * 0.25}
          >
            {val}
          </text>
        ))}
      </g>
    </svg>
  );
};

export default QOLChart;

import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./RadioYesNo.module.scss";

interface RadioYesNoProps {
  yesLabel?: string;
  noLabel?: string;
  field: any;
  form: any;
}

export default function RadioYesNo({
  yesLabel = "Yes",
  noLabel = "No",
  field,
  form,
}: RadioYesNoProps) {
  const { t } = useTranslation();

  return (
    <ul className={styles.options}>
      <li className={styles.option}>
        <input
          required={field.required}
          type="radio"
          name={field.name}
          id={field.name + "_yes"}
          defaultChecked={field.value === true}
          onChange={(e) =>
            e.target.value && form.setFieldValue(field.name, true)
          }
        />
        <label htmlFor={field.name + "_yes"}>{t(yesLabel)}</label>
      </li>
      <li className={styles.option}>
        <input
          required={field.required}
          type="radio"
          name={field.name}
          id={field.name + "_no"}
          defaultChecked={field.value === false}
          onChange={(e) =>
            e.target.value && form.setFieldValue(field.name, false)
          }
        />
        <label htmlFor={field.name + "_no"}>{t(noLabel)}</label>
      </li>
    </ul>
  );
}

import React from "react";
import Logo from "./assets/svg/endo-cy-logo.svg";

import styles from "./Header.module.scss";
import LanguageSwitcher from "./shared-components/LanguageSwitcher";

const Header = () => {
  return (
    <div className={styles.wrapper}>
      <LanguageSwitcher />
      <header className={styles.header}>
        <div>
          <a href="https://endometriosis.cymru">
            <img
              className={styles.headerLogo}
              src={Logo}
              alt="Endometriosis Cymru"
            />
          </a>
        </div>
      </header>
    </div>
  );
};
export default Header;

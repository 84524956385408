import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import firebase from "firebase";
import { firestore } from "../fire";

import { Response } from "./library/response";
import domains from "./library/domains";
import calculateScores from "./helpers/calculateScores";
import QOLChart from "./components/QOLChart";
import styles from "./Results.module.scss";

import { peopleType } from "./library/people";
import { useTranslation } from "react-i18next";

interface ResultsProps {
  people: peopleType;
}

const hasEndoMessages = {
  true: "Has or suspects endometriosis",
  false: "Does not have endometriosis",
};

export default function Results({ people }: ResultsProps) {
  const { id } = useParams<{ id: string }>();

  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [responses, setResponses] =
    useState<firebase.firestore.DocumentSnapshot<Response>>();

  useEffect(() => {
    firestore
      .collection("qol")
      .doc(id)
      .get()
      .then((docRef) => setResponses(docRef));
  }, [id]);

  const responsesA = responses?.get("a") || {};
  const responsesB = responses?.get("b") || {};
  const scoresA = calculateScores(responsesA);
  const scoresB = calculateScores(responsesB);
  const demographicsA = responses?.get("demographics.a") || {};
  const demographicsB = responses?.get("demographics.b") || {};

  return !responses ? (
    <div></div>
  ) : (
    <div>
      <h1 className="bigHeading">{t("resultsHeading")}</h1>
      <QOLChart
        people={people}
        a={responses?.get("a")}
        b={responses?.get("b")}
      />
      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            <th className={styles.cellNumber}>
              <h3 className={styles.thTitle}>{people.a.name}</h3>
              <div className={styles.thDescription}>
                {demographicsA.hasEndo
                  ? hasEndoMessages.true
                  : hasEndoMessages.false}
              </div>
            </th>
            <th className={styles.cellNumber}>
              <h3 className={styles.thTitle}>{people.b.name}</h3>
              <div className={styles.thDescription}>
                {demographicsB.hasEndo
                  ? hasEndoMessages.true
                  : hasEndoMessages.false}
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(domains).map((domain) => (
            <tr key={domain}>
              <th>
                <h3 className={styles.thTitle}>
                  {domains[domain].label[language]}
                </h3>
                <div
                  className={styles.thDescription}
                  dangerouslySetInnerHTML={{
                    __html: domains[domain].description[language],
                  }}
                />
              </th>
              <td className={styles.cellNumber}>
                {scoresA[domain] ? `${scoresA[domain]}/100` : "–"}
              </td>
              <td className={styles.cellNumber}>
                {scoresB[domain] ? `${scoresB[domain]}/100` : "–"}
              </td>
            </tr>
          ))}

          <tr>
            <th>{t("Overall Quality of Life Score")}</th>
            <td className={styles.cellNumber}>
              {scoresA.qol ? `${scoresA.qol}/100` : "–"}
            </td>
            <td className={styles.cellNumber}>
              {scoresB.qol ? `${scoresB.qol}/100` : "–"}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}
